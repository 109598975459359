import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import WeatherComparisonApp from './WeatherComparisonApp';
import './styles/LoadingSpinner.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WeatherComparisonApp />
  </React.StrictMode>
);