import React, { useState, useEffect } from 'react';
import { Sun, Cloud, CloudRain, Wind, Droplets, Thermometer, Umbrella, ChevronDown, Moon, Search } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';



// Helper function to get date string in mm/dd format
const getDateString = (daysFromNow = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + daysFromNow);
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

// Helper function to get day of week
const getDayOfWeek = (daysFromNow = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + daysFromNow);
  return date.toLocaleDateString('en-US', { weekday: 'short' });
};

// Weather icon component
const WeatherIcon = ({ condition, size = 24 }) => {
  switch (condition) {
    case 'Clear': return <Sun size={size} className="text-yellow-500" />;
    case 'Clouds': return <Cloud size={size} className="text-gray-500" />;
    case 'Rain': return <CloudRain size={size} className="text-blue-500" />;
    default: return <Cloud size={size} className="text-gray-500" />;
  }
};

// CitySearch component
const CitySearch = ({ onSelect, initialCity }) => {
  const [query, setQuery] = useState(initialCity?.name || '');
  const [suggestions, setSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);

  const fetchCitySuggestions = async (query) => {
    try {
      const api_url = `http://localhost:1999/api/geo/direct?q=${query}&limit=5`
      const response = await fetch(api_url);
      const data = await response.json();

      console.log('API Response:', data); // Log the API response

      if (!Array.isArray(data)) {
        console.error('Expected array, got:', typeof data);
        throw new Error('Invalid response format');
      }

      return data.map(city => ({
        name: city.country === 'US' ? `${city.name}, ${city.state}, ${city.country}` : `${city.name}, ${city.country}`,
        lat: city.lat,
        lon: city.lon
      }));
    } catch (error) {
      console.error('Error fetching city suggestions:', error);
      setError('Failed to fetch city suggestions. Please try again.');
      return [];
    }
  };

  React.useEffect(() => {
    if (query.length > 2 && isSearching) {
      fetchCitySuggestions(query)
        .then(setSuggestions)
        .catch(error => {
          console.error('Error in useEffect:', error);
          setError('An error occurred while fetching suggestions.');
        });
    } else {
      setSuggestions([]);
    }
  }, [query, isSearching]);

  return (
    <div className="relative">
      <input
        type="text"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          setIsSearching(true);
          setError(null);
        }}
        onFocus={() => setIsSearching(true)}
        placeholder="Search for a city"
        className="w-full p-2 border rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
      />
      {error && <div className="text-red-500 dark:text-red-400 mt-1">{error}</div>}
      {suggestions.length > 0 && isSearching && (
        <ul className="absolute z-10 w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded mt-1">
          {suggestions.map((city, index) => (
            <li
              key={index}
              onClick={() => {
                onSelect(city);
                setQuery(city.name);
                setIsSearching(false);
              }}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer text-gray-900 dark:text-white"
            >
              {city.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

// Custom dropdown component
const CustomDropdown = ({ options, value, onChange, label }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block text-left w-full">
      <div
        className="w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 flex justify-between items-center cursor-pointer text-gray-900 dark:text-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{options.find(opt => opt.value === value)?.label || label}</span>
        <ChevronDown size={20} />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded shadow-lg">
          {options.map((option) => (
            <div
              key={option.value}
              className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer text-gray-900 dark:text-white"
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Current weather component
const CurrentWeather = ({ data, city }) => (
  <Card className="mb-4">
    <CardHeader>
      <CardTitle>{city} - Current Weather</CardTitle>
    </CardHeader>
    <CardContent className="flex items-center justify-between">
      <div className="flex items-center">
        <WeatherIcon condition={data.weather[0].main} size={48} />
        <div className="ml-4">
          <p className="text-4xl font-bold">{Math.round(data.temp)}°F</p>
          <p className="text-xl capitalize">{data.weather[0].description}</p>
        </div>
      </div>
      <div>
        <p><Wind className="inline mr-2" /> Wind: {Math.round(data.wind_speed)} mph</p>
        <p><Droplets className="inline mr-2" /> Humidity: {data.humidity}%</p>
        <p><Thermometer className="inline mr-2" /> UV Index: {Math.round(data.uvi)}</p>
        <p><Umbrella className="inline mr-2" /> Precipitation: {Math.round(data.pop * 100)}%</p>
      </div>
    </CardContent>
  </Card>
);

// Function to fetch current and forecast weather data
const fetchWeatherData = async (lat, lon) => {
  const api_url = `http://localhost:1999/api/onecall?lat=${lat}&lon=${lon}&exclude=minutely&units=imperial`
  const response = await fetch(api_url);
  return await response.json();
};

// Updated function to fetch historical data
const fetchHistoricalData = async (lat, lon) => {
  try {
    const response = await fetch(`http://localhost:1999/api/history?lat=${lat}&lon=${lon}`);
    const data = await response.json();

    if (!Array.isArray(data)) {
      console.error('Unexpected data structure:', data);
      return [];
    }

    // Process the data to get monthly averages
    const monthlyData = data.map(item => ({
      month: new Date(item.month + '-01').toLocaleString('default', { month: 'short' }),
      temp: (item.temp - 273.15) * 9 / 5 + 32, // Convert Kelvin to Fahrenheit
      humidity: item.humidity
    }));

    return monthlyData;
  } catch (error) {
    console.error('Error fetching historical data:', error);
    return [];
  }
};


// Forecast chart component
const ForecastChart = ({ data, city1, city2, metric, timeframe }) => {
  // Prepare data based on the selected timeframe
  const prepareData = () => {
    switch (timeframe) {
      case 'hourly':
        return data[city1].hourly.map((hour, index) => ({
          time: new Date(hour.dt * 1000).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true }),
          [city1]: hour[metric],
          [city2]: data[city2].hourly[index][metric]
        }));
      case 'daily':
        return data[city1].daily.map((day, index) => ({
          time: getDayOfWeek(index),
          [city1]: day.temp.max,
          [city2]: data[city2].daily[index].temp.max
        }));
      case 'monthly':
        return data[city1].monthly.map((month, index) => ({
          time: month.month,
          [city1]: month[metric],
          [city2]: data[city2].monthly[index][metric]
        }));
      default:
        return [];
    }
  };

  const chartData = prepareData();

  // Calculate y-axis domain for temperature
  const calculateTempDomain = () => {
    if (metric !== 'temp') return [0, 'auto'];

    const allTemps = chartData.flatMap(d => [d[city1], d[city2]]);
    const minTemp = Math.floor(Math.min(...allTemps));
    const maxTemp = Math.ceil(Math.max(...allTemps));
    const padding = Math.round((maxTemp - minTemp) * 0.1); // Add 10% padding

    return [minTemp - padding, maxTemp + padding];
  };

  // Determine y-axis label and domain based on the selected metric
  const yAxisLabel = metric === 'temp' ? '°F' : '%';
  const yAxisDomain = metric === 'temp' ? calculateTempDomain() : [0, 100];

  return (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle>
          {`${timeframe.charAt(0).toUpperCase() + timeframe.slice(1)} ${metric === 'temp' ? 'Temperature' : 'Humidity'} Comparison`}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis
              label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }}
              domain={yAxisDomain}
              tickFormatter={value => metric === 'temp' ? `${Math.round(value)}°` : `${value}%`}
            />
            <Tooltip
              formatter={(value, name) => [
                metric === 'temp' ? `${Math.round(value)}°F` : `${value}%`,
                name
              ]}
            />
            <Legend />
            <Line type="monotone" dataKey={city1} stroke="#8884d8" />
            <Line type="monotone" dataKey={city2} stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

// Daily forecast component
const DailyForecast = ({ data, city }) => (
  <Card className="mb-4">
    <CardHeader>
      <CardTitle>{city} - 5-Day Forecast</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="flex justify-between">
        {data.slice(0, 5).map((day, index) => (
          <div key={index} className="text-center">
            <p className="font-bold">{getDayOfWeek(index)}</p>
            <p className="text-sm">{getDateString(index)}</p>
            <WeatherIcon condition={day.weather[0].main} size={24} />
            <p>{Math.round(day.temp.max)}° / {Math.round(day.temp.min)}°</p>
          </div>
        ))}
      </div>
    </CardContent>
  </Card>
);

// Loading spinner component
const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-64">
    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div>
);

// Main weather comparison app component
const WeatherComparisonApp = () => {
  const [city1, setCity1] = useState(null);
  const [city2, setCity2] = useState(null);
  const [weatherData, setWeatherData] = useState({});
  const [chartMetric, setChartMetric] = useState("temp");
  const [chartTimeframe, setChartTimeframe] = useState("hourly");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Clear weather data when either city changes
    setWeatherData({});
  }, [city1, city2]);

  useEffect(() => {
    // Apply dark mode class to body
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  const handleSearch = async () => {
    if (city1 && city2) {
      setIsLoading(true);
      setError(null);
      setWeatherData({}); // Clear previous data
      try {
        const data1 = await fetchAllWeatherData(city1);
        const data2 = await fetchAllWeatherData(city2);
        setWeatherData({
          [city1.name]: data1,
          [city2.name]: data2
        });
      } catch (error) {
        console.error("Error fetching weather data:", error);
        setError("Failed to fetch weather data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Function to fetch all weather data (current, forecast, and historical)
  const fetchAllWeatherData = async (city) => {
    try {
      const currentAndForecast = await fetchWeatherData(city.lat, city.lon);
      const historicalData = await fetchHistoricalData(city.lat, city.lon);
      return { ...currentAndForecast, monthly: historicalData };
    } catch (error) {
      console.error("Error fetching all weather data:", error);
      throw error; // Re-throw the error to be caught by the handleSearch function
    }
  };

  // Options for dropdowns
  const metricOptions = [
    { value: "temp", label: "Temperature" },
    { value: "humidity", label: "Humidity" }
  ];
  const timeframeOptions = [
    { value: "hourly", label: "Hourly" },
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" }
  ];

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const renderWeatherData = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (error) {
      return <div className="text-red-500 text-center mb-4">{error}</div>;
    }

    if (Object.keys(weatherData).length === 2 && weatherData[city1.name] && weatherData[city2.name]) {
      return (
        <>
          <div className="grid grid-cols-2 gap-4">
            <CurrentWeather data={weatherData[city1.name].current} city={city1.name} />
            <CurrentWeather data={weatherData[city2.name].current} city={city2.name} />
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <CustomDropdown
              options={metricOptions}
              value={chartMetric}
              onChange={setChartMetric}
              label="Select Metric"
            />
            <CustomDropdown
              options={timeframeOptions}
              value={chartTimeframe}
              onChange={setChartTimeframe}
              label="Select Timeframe"
            />
          </div>

          <ForecastChart
            data={weatherData}
            city1={city1.name}
            city2={city2.name}
            metric={chartMetric}
            timeframe={chartTimeframe}
          />

          <DailyForecast data={weatherData[city1.name].daily} city={city1.name} />
          <DailyForecast data={weatherData[city2.name].daily} city={city2.name} />
        </>
      );
    }

    return null;
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <div className="transition-colors duration-200 ease-in-out">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Weather Comparison</h1>
          <button
            onClick={toggleDarkMode}
            className="p-2 rounded-full bg-gray-200 dark:bg-gray-700"
          >
            {isDarkMode ? <Sun  className="text-black" size={24} /> : <Moon size={24} />}
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <CitySearch onSelect={setCity1} initialCity={city1} />
          <CitySearch onSelect={setCity2} initialCity={city2} />
        </div>

        <div className="flex justify-center mb-4">
          <button
            onClick={handleSearch}
            disabled={!city1 || !city2 || isLoading}
            className={`flex items-center justify-center px-4 py-2 bg-[#CC7C5E] text-white rounded-md ${(!city1 || !city2 || isLoading) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
          >
            <Search className="mr-2" size={20} />
            Compare Weather
          </button>
        </div>

        {renderWeatherData()}
      </div>
    </div>
  );
};

export default WeatherComparisonApp;