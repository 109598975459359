// src/components/ui/card.js

export function Card({ className, ...props }) {
    return <div className={`bg-[var(--card-bg)] rounded-lg shadow ${className}`} {...props} />
}


// CardHeader component for card headers
export function CardHeader({ className, ...props }) {
    return <div className={`p-6 ${className}`} {...props} />
}

// CardTitle component for card titles
export function CardTitle({ className, ...props }) {
    return <h3 className={`text-2xl font-bold ${className}`} {...props} />
}

// CardContent component for card content
export function CardContent({ className, ...props }) {
    return <div className={`p-6 pt-0 ${className}`} {...props} />
}